$(function () {
    $(document).on('keyup', 'input[data-autosubmit]', function (e) {
        let code = e.which || e.keyCode || 0;

        if(code === 13) {
            e.preventDefault();
        }

        if ((code !== 13) && ((code >= 9 && code <= 40) || (code >= 112 && code <= 123))) {
            return;
        }

        clearTimeout(window.datagridTimer);

        let $this= $(this);

        window.datagridTimer = setTimeout(function() {
            $this.closest('form').submit();
        }, 600);
    })
    .on('change', 'input[data-autosubmit-change]', function (e) {
        $(this).closest('form').submit();
    })
    .on('change', 'select[data-autosubmit-change]', function (e) {
        $(this).closest('form').submit();
    })
    .on('change', 'textarea[data-autosubmit-change]', function (e) {
        $(this).closest('form').submit();
    });
});