import 'core-js';

import '@shared/common.js';
import './less/portal.less';

var req = require.context('./img/', true, /\.(png|jpe?g|svg)$/);
req.keys().forEach(req);

import Vue from 'vue/dist/vue.esm.js';
global.Vue = Vue;


import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

//ajax
import naja from 'naja';
window.naja = naja;