$(function () {
    if(window.localStorage.getItem("rememberTabsState") == null) {
        window.localStorage.setItem("rememberTabsState", JSON.stringify({}));
    }


    $(".nav-tabs-custom[data-remember-tab-state]").on("click", function(e) {
        let $key = $(this).data("remember-tab-state");

        var $states = JSON.parse(window.localStorage.getItem("rememberTabsState") || "[]");
        $states[$key] = $(e.target).attr('href');

        window.localStorage.setItem('rememberTabsState', JSON.stringify($states));
    });

    $(".nav-tabs-custom[data-remember-tab-state]").each(function () {
        let $key = $(this).data("remember-tab-state");
        var $states = JSON.parse(window.localStorage.getItem("rememberTabsState") || "[]");

        if ($states[$key]) {
            $(this).find("a[href='" + $states[$key] + "']").tab('show');
            //$states.splice($key, 1);
        }
    });
});