import 'bootstrap/dist/js/bootstrap.js';
import Nette from 'vendor/nette/forms/src/assets/netteForms.js';
import "admin-lte/dist/js/adminlte.js";
import 'nette.ajax.js/nette.ajax.js';


import 'jquery.cookie/jquery.cookie.js';
import 'chart.js/dist/Chart.js';
import 'moment/moment.js';
import 'moment/locale/cs.js';
import 'bootstrap-notify/bootstrap-notify.min';
import 'pretty-checkbox/dist/pretty-checkbox.css';

//select2
import 'select2/dist/css/select2.css';
import 'select2/dist/js/select2.full.js';
import 'select2/dist/js/i18n/cs.js';

import 'jquery.scrollto/jquery.scrollTo.min';
import 'jquery.kinetic/jquery.kinetic.js';
//import 'jquery-ui/jquery-ui.js';

import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';

import 'venobox/venobox/venobox.js';
import 'venobox/venobox/venobox.css';


import 'nouislider/distribute/nouislider.min';
import 'sticky-kit/dist/sticky-kit.min';


import './js/allowUncheck.js';
import './js/datagrid.js';
import './js/spinner.js';
import './js/rememberTabsState.js';
import './js/chartJsPluginTextCenter.js';
import './js/bootstrapModal.js';


import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'bootstrap-select/dist/js/bootstrap-select.js';
import 'bootstrap-select/dist/js/i18n/defaults-cs_CZ.js';

import 'bootstrap-colorpicker/dist/js/bootstrap-colorpicker';
import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css';

$(function () {
    Nette.initOnLoad();
    $.nette.init();


    $('.venobox').venobox();


    if($.cookie('sidebarExpanded') === 0) {
        var body = document.getElementsByTagName('body')[0];
        body.className = body.className + ' sidebar-collapse';
    }

    //remember sidebar state
    $("body").on('expanded.pushMenu', function (e) {
        $.cookie('sidebarExpanded', 1);
    }).on('collapsed.pushMenu', function (e) {
        $.cookie('sidebarExpanded', 0);
    });
});



//disable button while ajax
$.nette.ext("disableButton", {
    start: function (xhr, settings) {
        if (!settings.nette || !settings.nette.el.is(":submit")) {
            return;
        }

        let $el = settings.nette.el;

        $el.attr('disabled', true);
        $el.addClass('disabled');
    },
    complete: function (xhr, status, settings) {
        if (settings.nette && settings.nette.el) {
            settings.nette.el.removeAttr('disabled');
            settings.nette.el.removeClass('disabled');
        }
    }
});


import jQuery from 'jquery';

global.Nette = Nette;
global.$ = jQuery;
global.jQuery = jQuery;
global.moment = require('moment');

var req = require.context('./img/', true, /\.(png|jpe?g|svg)$/);
req.keys().forEach(req);


//fix IE6 error with promise
var Promise = require('es6-promise').Promise;


