$(function () {

    $("body").on('click', "input[type='radio'].allowUncheck", function() {
        if($(this).prop('previousValue') === true){
            $(this).prop('checked', false);
            $(this).change();
        } else {
            let $name = $.escapeSelector($(this).prop("name"));
            $('input[name=' + $name + ']').prop('previousValue', false);
        }

        $(this).prop('previousValue', $(this).prop('checked'));
    });
});

