$(function() {
    $.nette.ext('bs-modal', {
        init: function () {
            this.ext('snippets', true).after($.proxy(function ($el) {
                if (!$el.is('.modal')) {
                    return;
                }

                $el.modal({});

            }, this));

            $('.modal[id^="snippet-"]').each(function () {
                let content = $(this).find('.modal-content').html();
                if (content === undefined || !content.length) {
                    $(this).modal('hide');
                    return; // ignore empty modal
                }

                $(this).modal();
            });
        },
        complete: function () //skryjeme modaly bez obsahu
        {
            $('.modal[id^="snippet-"]').each(function () {
                let content = $(this).find('.modal-content').html();
                if (content === undefined || !content.length)
                {
                    $(this).modal('hide');
                }
            });
        }
    });



    /////////////////////////////////////////////////////////////
    var $modal = $(".modal");

    //disable message when is clicked on the close button
    $modal.on("click", "button[data-dismiss='modal']", function () {
        $(this).closest(".modal").removeAttr('data-form-data');
    });

    //save form state
    $modal.on('shown.bs.modal', function(e) {
        // set form state
        $(this).attr('data-form-data', $(this).find('form').serialize());

        // Optional attribute for disable confirm message: data-no-confirm-message
        let $noConfirmMessage = $(this).attr('data-no-confirm-message');
        if (typeof $noConfirmMessage !== typeof undefined && $noConfirmMessage !== false) {
            $(this).closest(".modal").removeAttr('data-form-data');
        }
    });

    $modal.on('hide.bs.modal', function(e) {
        let $persisted = $(this).attr('data-form-data');
        let $actual = $(this).find('form').serialize();

        if($actual === "") {
            return;
        }

        var content = $(this).find('.modal-content');
        if (!content.length || $persisted === undefined) {
            return;
        }

        if($persisted !== $actual && !confirm($locale.bootstrapModalClose)) {
            e.preventDefault();
        }
    });
});

