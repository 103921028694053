$(function () {
    $.nette.ext('spinner', {
        start: function(jqXHR, settings) {

            if(!settings.nette || !settings.nette.el.is(".spinner")) {
                return;
            }

            let $element = settings.nette.el;
            let $target = null;

            if($element.data("spinner-target")) {
                $target = $($element.data("spinner-target"));

                if($target.not(".box")) {
                    $target.addClass("overlay-wrapper");
                    $target.css("position", "relative");
                }
            } else {
                $target = $element.closest(".box");
            }


            if($target.find(".overlay").length > 0) {
                return;
            }

            window.spinnerTarget = $target;

            //show spinner
            $target.append($('<div class="overlay">' +
                '                             <i class="fas fa-spinner fa-spin fa-2x"></i>' +
                '                          </div>'));
        },
        complete: function(payload, status, jqXHR, settings){
            if(!window.spinnerTarget) {
                return;
            }

            let $target = window.spinnerTarget;

            //remove spinner
            $target.find(".overlay").fadeOut(300, function() { $(this).remove(); });
        }
    });
});